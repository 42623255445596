export const PLACE = (t) => [
  {
    ...(process.env.NODE_ENV === "production"
      ? {}
      : { value: "", name: "Vietnam" }),
  },
  { value: "Afghanistan", name: "Afghanistan" },
  { value: "Aland Islands", name: "Aland Islands" },
  { value: "Albania", name: "Albania" },
  { value: "Algeria", name: "Algeria" },
  { value: "AmericanSamoa", name: "AmericanSamoa" },
];

// export const TIME = () => {
//   const today = new Date();
//   const tomorrow = new Date(today);
//   tomorrow.setDate(today.getDate() + 1);
//   return [
//     { value: "today", name: "Today" },
//     { value: "tomorrow", name: "Tomorrow" },
//     { value: "show", name: "Another time period" },
//   ];
// };

export const CATEGORY = (t) => [
  { value: "", name: t("all") },
  { value: "SPORT", name: t("sport") },
  { value: "CONCERT", name: t("concert") },
  { value: "FOOD_COURT", name: t("food_court") },
];

export const SPORT = "SPORT";
export const CONCERT = "CONCERT";
export const FOOD_COURT = "FOOD_COURT";

export const STATUS_MY_TICKETS = (t) => [
  { value: "", name: t("all") },
  { value: "CHECKED_IN", name: t("checked_in") },
  { value: "WAIT_FOR_CHECKIN", name: t("waiting") },
  { value: "EXPIRED", name: t("expire") },
];

export const PAYMENT_METHOD_OPTION = [
  { name: "All", value: "ALL" },
  {
    name: "VISA",
    value: "VISA",
  },
  // {
  //   name: "STCPAY",
  //   value: "STCPAY",
  // },
  {
    name: "MADA",
    value: "MADA",
  },
  {
    name: "MASTERCARD",
    value: "MASTERCARD",
  },
];

export const TICKET_STATUS = (t) => [
  { name: t("all"), value: "" },
  { name: t("checked_in"), value: "checkedIn" },
  { name: t("waiting"), value: "waitingForCheckIn" },
  { name: t("expired"), value: "expired" },
];

export const TICKET_TYPES = (t) => [
  {
    name: t("sold_ticket"),
    value: "NORMAL",
  },
  {
    name: t("generate_tickets"),
    value: "FREE",
  },
];

export const LANGUAGES = {
  ARABIC: "ar",
  ENGLISH: "en",
};

export const APPLICATION_RESPONSE_CODE = "responseCode";

export const EVENT_GAME_APPLICATION_CODE = {
  LOCATEXG203: "LOCATEXG203",
  LOCATEXG204: "LOCATEXG204",
  LOCATEXG205: "LOCATEXG205",
};

export const EVENT_GAME_MAPPING_MESSAGE_ERROR = {
  LOCATEXG203: "User not found",
  LOCATEXG204: "Session is still active, please try again after 1 minute",
};

export const EVENT_GAME_STEPS = {
  EVENT_GAMES_INTRODUCTION: "EVENT_GAMES_INTRODUCTION",
  EVENT_GAMES_REGISTER: "EVENT_GAMES_REGISTER",
  EVENT_GAMES_SHUKAH_REGISTER: "EVENT_GAMES_SHUKAH_REGISTER",
  EVENT_GAMES_SUCCESSFUL: "EVENT_GAMES_SUCCESSFUL",
  EVENT_GAMES_ERROR: "EVENT_GAMES_ERROR",
};

export const COUNTRY_CODE = {
  SA: "SA",
  VN: "VN",
};
