"use client";
import i18next from "i18next";
import { Almarai, DM_Sans } from "next/font/google";
import { useEffect, useState } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import AuthContextProvider from "../contexts/AuthContext";
import arabic from "../language/arabic.json";
import english from "../language/english.json";
import "../styles/custom-phone-input.css";
import "../styles/custom-react-datepicker.css";
import "../styles/globals.css";

import "react-phone-number-input/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import { Provider } from "next-auth/client";
// import AOS from "aos";
// import "aos/dist/aos.css";
import moment from "moment-timezone";
import Head from "next/head";
import { useRouter } from "next/router";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../components/footer/Footer";
import DataContextProvider from "../contexts/DataContext";
import { APPLICATION_RESPONSE_CODE } from "../constant/constants";

moment.tz.setDefault("Asia/Riyadh");

export const dm_sans = DM_Sans({
  weight: ["400", "500", "700"],
  style: ["normal"],
  subsets: ["latin-ext", "latin"],
});
export const almarai = Almarai({
  weight: ["400", "700"],
  style: ["normal"],
  subsets: ["arabic"],
});

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const [lang, setLang] = useState(null);

  useEffect(() => {
    if (typeof window === undefined) return;
    const curLang = localStorage?.getItem("language")?.length
      ? localStorage.getItem("language")
      : "ar";
    let dir = curLang === "ar" ? "rtl" : "ltr";
    document.querySelector("html").setAttribute("dir", dir);
    document.querySelector("html").setAttribute("lang", curLang);
    setLang(curLang);
  }, []);

  useEffect(() => {
    const excludedPages = ["/login", "/update-profile"];
    const currentPath = window.location.pathname;
    const pathWithoutQueryParams = currentPath.split("?")[0];
    const isExcludedPage = excludedPages.includes(pathWithoutQueryParams);

    const foodCourt = [
      "/foodcourt-event",
      "/foodcourt-event-register",
      "/event-game/",
      "/qr-codes",
    ];
    const isExcludedFoodCourt =
      foodCourt.includes(pathWithoutQueryParams) ||
      foodCourt.some((page) => pathWithoutQueryParams.startsWith(page));
    const urlParams = new URLSearchParams(window.location.search);
    const foodCourtResponse = urlParams.get(APPLICATION_RESPONSE_CODE);

    if (foodCourtResponse) {
      const backgroundImage = document.getElementById("yyy");
      if (backgroundImage) {
        backgroundImage.style.backgroundImage =
          "url('https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvbzxxuh4db/b/bucket-booking-prod/o/img-prod%2Ffc_event_bg.jpg')";
        backgroundImage.style.backgroundRepeat = "no-repeat";
        backgroundImage.style.backgroundSize = "cover";
      }
    }
    if (isExcludedFoodCourt) {
      const backgroundImage = document.getElementById("yyy");
      if (backgroundImage) {
        backgroundImage.style.backgroundImage =
          "url('https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvbzxxuh4db/b/bucket-booking-prod/o/img-prod%2Ffc_event_bg.jpg')";
        backgroundImage.style.backgroundRepeat = "no-repeat";
        backgroundImage.style.backgroundSize = "cover";
      }
    }

    if (isExcludedPage) {
      const backgroundImage = document.getElementById("yyy");
      if (backgroundImage) {
        backgroundImage.style.backgroundImage = "url('/images/bg-home.png')";
        backgroundImage.style.backgroundRepeat = "no-repeat";
        backgroundImage.style.backgroundSize = "cover";
      }
    }
    return () => {
      const backgroundImage = document.getElementById("yyy");
      if (backgroundImage) {
        backgroundImage.style.backgroundImage = "";
        backgroundImage.style.backgroundRepeat = "";
        backgroundImage.style.backgroundSize = "";
      }
    };
  });

  if (!lang) return;

  if (!i18next.isInitialized) {
    i18next.use(initReactI18next).init({
      interpolation: { escapeValue: false }, // React already does escaping
      lng: lang, // language to use
      debug: true,
      resources: {
        en: {
          translation: english, // 'common' is our custom namespace
        },
        ar: {
          translation: arabic,
        },
      },
      react: {
        useSuspense: true,
      },
    });
  }
  console.log("env=>>>", process.env.NODE_ENV);

  return (
    <I18nextProvider i18n={i18next}>
      <AppProvider>
        <div
          id="yyy"
          className={` flex items-center  justify-center bg-secondary-100 ${almarai.className}`}
        >
          <Head>
            <link rel="shortcut icon" href="/logo_png.png" />
            <title>Locate Events</title>
            <script
              defer
              src={`https://wait.crowdhandler.com/js/latest/main.js?id=${process.env.NEXT_PUBLIC_CROWD_HANDLE}&spa=true`}
            />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
          </Head>
          <div id="xxx" className="w-full h-full max-w-screen-2xl ">
            <style jsx global>{`
              * {
                font-family: ${almarai.style.fontFamily};
              }
            `}</style>
            <Component {...pageProps} />
          </div>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover={false}
          />
        </div>
      </AppProvider>
    </I18nextProvider>
  );
}
const AppProvider = ({ children }) => {
  const excludedPages = [
    "/login",
    "/register",
    "/update-profile",
    "/foodcourt-event",
    "/foodcourt-event-register",
    "/event-game",
    "/qr-codes",
  ];
  const router = useRouter();

  if (
    excludedPages.includes(router.route) ||
    excludedPages.some((page) => router.route.startsWith(page))
  ) {
    return (
      <AuthContextProvider>
        <DataContextProvider>
          <div className="flex flex-col justify-between min-h-screen bg-secondary-100">
            <div className="h-full bg-secondary-100">{children}</div>
          </div>
        </DataContextProvider>
      </AuthContextProvider>
    );
  }

  return (
    <AuthContextProvider>
      <DataContextProvider>
        <div className="flex flex-col justify-between min-h-screen bg-secondary-100">
          <Header />
          <div className="h-full bg-secondary-100">
            <div className="pt-[88px]">{children}</div>
          </div>
          <Footer />
        </div>
      </DataContextProvider>
    </AuthContextProvider>
  );
};

export default MyApp;
